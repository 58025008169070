import React, { useContext } from "react"
import { Context } from "../../utils/Context"
import Brand from "../brand/Brand"
import Menu from "../menu/Menu"
import css from "./Header.module.css"

export default ({ theme }) => {
  const { state } = useContext(Context)
  return (
    <header
      className={`${css.header} ${css[theme]} ${
        state.menu.isMenuOpen ? css.isOpen : ""
      }`}
    >
      <div className={css.headerContent}>
        <Brand type="logo" filetype="svg" isLink={true} theme={theme} />
        <Menu theme={theme} />
      </div>
    </header>
  )
}
