import React, { useContext } from "react"
import { Link } from "gatsby"
import { Context } from "../../utils/Context"
import { menuToggle } from "./Actions"
import ButtonToggle from "../../components/button/ButtonToggle"
import ButtonLink from "../../components/button/ButtonLink"
import css from "./Menu.module.css"

export default ({ theme }) => {
  const { state, dispatch } = useContext(Context)
  return (
    <div
      className={`${css.menu} ${css[theme]} ${
        state.menu.isMenuOpen ? css.isOpen : ""
      }`}
    >
      <ButtonToggle
        className={css.burguer}
        icons={{ on: "close", off: "menu" }}
        noBG
        fx={active => {
          dispatch(menuToggle(active))
        }}
        init={state.menu.isMenuOpen}
      ></ButtonToggle>

      <nav className={css.navPages}>
        <Link
          to="/"
          className={css.navItem}
          activeClassName={css.active}
          onClick={() => dispatch(menuToggle(false))}
        >
          Inicio
        </Link>
        <Link
          to="/nosotras"
          className={css.navItem}
          getProps={({ isPartiallyCurrent }) =>
            isPartiallyCurrent
              ? { className: `${css.navItem} ${css.active}` }
              : null
          }
          onClick={() => dispatch(menuToggle(false))}
        >
          Nosotras
        </Link>
        <Link
          to="/feminismo"
          className={css.navItem}
          activeClassName={css.active}
          onClick={() => dispatch(menuToggle(false))}
        >
          Feminismo
        </Link>
        <ButtonLink
          className={css.credimujer}
          icon="receipt_long"
          url="https://www.starsoftweb.com/factronweb"
          activeClassName={css.active}
          external
          fx={() => dispatch(menuToggle(false))}
        >
          Credimujer
        </ButtonLink>
      </nav>
    </div>
  )
}
